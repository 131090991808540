import Dashboard from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import DashboardPage from "views/Dashboard/Dashboard.js";
import RaiseTicket from "views/RaiseTicket/RaiseTicket";
import Staffs from "views/Sfatts/Staffs";
import TicketStatusAdmin from "views/TicketStatusAdmin/TicketStatusAdmin";
import WorkLog from "views/WorkLog/WorkLog";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/raise-ticket",
    name: "Raise New Ticket",
    icon: "content_paste",
    component: RaiseTicket,
    layout: "/admin",
  },
  {
    path: "/ticket-status",
    name: "Ticket Status",
    icon: "content_paste",
    component: TicketStatusAdmin,
    layout: "/admin",
  },

  {
    path: "/developers",
    name: "Developers",
    icon: PeopleIcon,
    component: Staffs,
    layout: "/admin",
  },
  {
    path: "/work-log",
    name: "Work Log",
    icon: PeopleIcon,
    component: WorkLog,
    layout: "/admin",
  },
];

export default dashboardRoutes;
