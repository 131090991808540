import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
// import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import NavPills from "components/NavPills/NavPills.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Button from "components/CustomButtons/Button.js";
import { Domain } from "Domain";
// import axios from "axios";
// import Danger from "components/Typography/Danger";
import LoadingOverlay from "react-loading-overlay";
// import ImageUpload from "components/CustomUpload/ImageUpload.js";
// import Accordion from "components/Accordion/Accordion";
// import Pagination from "components/Pagination/Pagination";
import axios from "axios";
// import Success from "components/Typography/Success";

import SnackbarContent from "components/Snackbar/SnackbarContent";
import SingleSelect from "components/SingleSelect";
import Danger from "components/Typography/Danger";
// import AttachFile from "@material-ui/icons/AttachFile";
// import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";

// import Data from "./Data.json";
const styles = {
  paginationLink: {
    ":first-of-type": {
      marginleft: "0",
    },

    letterSpacing: "unset",
    border: "0",
    borderRadius: "50px !important",
    transition: "all .3s",
    padding: "0px 11px",
    margin: "0 3px",
    minWidth: "30px",
    height: "30px",
    minHeight: "auto",
    lineHeight: "30px",
    fontWeight: "400",
    fontSize: "15px",
    textTransform: "uppercase",
    background: "#0cb4c9",
    position: "relative",
    float: "left",
    textDecoration: "none",
    boxSizing: "border-box",
    "&,&:hover,&:focus": {
      color: "white",
    },
    "&:hover,&:focus": {
      zIndex: "3",
      backgroundColor: "#0cb4c9",
      borderColor: "#000000",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TicketStatusAdmin() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [Raised, setRaised] = useState([]);
  const [Assigned, setAssigned] = useState([]);
  const [Working, setWorking] = useState([]);
  const [Completed, setCompleted] = useState([]);
  const [Pending, setPending] = useState([]);
  const [Developers, setDevelopers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [RaisedEmpty, setRaisedEmpty] = useState(true);
  const [AssignedEmpty, setAssignedEmpty] = useState(true);
  const [WorkingEmpty, setWorkingEmpty] = useState(true);
  const [CompletedEmpty, setCompletedEmpty] = useState(true);
  const [PendingEmpty, setPendingEmpty] = useState(true);
  const [viewData, setviewData] = useState(false);
  const [edit, setEdit] = useState("");
  const [DevError, setError] = useState(false);
  const [TicketDetials, setTicketDetails] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    console.log(RaisedData);
  }, []);
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };

  //To get Raised Tickets list from database
  useEffect(() => {
    let formData = new FormData();

    formData.append("Status", "Raised");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Raised Response:", response);
        if (response.data.success) {
          if (response.data.TotalCount != 0) {
            setRaised(response.data.data);
            setLoading(false);
            setRaisedEmpty(false);
          } else {
            setRaisedEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Assigned Tickets list from database
  useEffect(() => {
    let formData = new FormData();

    formData.append("Status", "Assigned");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Assigned Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setAssigned(response.data.data);
            setAssignedEmpty(false);
            setLoading(false);
          } else {
            setAssignedEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Working Tickets list from database
  useEffect(() => {
    let formData = new FormData();

    formData.append("Status", "Working");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Working Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setWorking(response.data.data);
            setWorkingEmpty(false);
            setLoading(false);
          } else {
            setWorkingEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Completed Tickets list from database
  useEffect(() => {
    let formData = new FormData();

    formData.append("Status", "Completed");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Completed Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setCompleted(response.data.data);
            setLoading(false);
            setCompletedEmpty(false);
          } else {
            setCompletedEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);
  //To get Pending Tickets list from database
  useEffect(() => {
    let formData = new FormData();

    formData.append("Status", "Pending");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Pending Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setPending(response.data.data);
            setLoading(false);
            setPendingEmpty(false);
          } else {
            setPendingEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);
  //Maping Data to tables
  const RaisedData = Raised.map((d) => ({
    Status: (
      <>
        <SnackbarContent color="warning" message={d.Status}>
          {" "}
        </SnackbarContent>
      </>
    ),
    Date: d.CreateDate.split(" ")[0],
    Id: d.id,
    Project: d.Project,
    Subject: d.Subject,
    Description: d.Description.slice(0, 100),
  }));
  const AssignedData = Assigned.map((d) => ({
    Status: (
      <>
        <SnackbarContent color="primary" message={d.Status}>
          {" "}
        </SnackbarContent>
      </>
    ),
    DeveloperName: (
      <>
        <SnackbarContent color="warning" message={d.DeveloperName}>
          {" "}
        </SnackbarContent>
      </>
    ),
    Date: d.CreateDate.split(" ")[0],
    Id: d.id,
    Project: d.Project,
    Subject: d.Subject,
    Description: d.Description.slice(0, 100),
  }));
  const WorkingData = Working.map((d) => ({
    Status: (
      <>
        <SnackbarContent color="info" message={d.Status}>
          {" "}
        </SnackbarContent>
      </>
    ),
    DeveloperName: (
      <>
        <SnackbarContent color="warning" message={d.DeveloperName}>
          {" "}
        </SnackbarContent>
      </>
    ),
    Date: d.CreateDate.split(" ")[0],
    Id: d.id,
    Project: d.Project,
    Subject: d.Subject,
    Description: d.Description.slice(0, 100),
  }));
  const CompletedData = Completed.map((d) => ({
    Status: (
      <>
        <SnackbarContent color="success" message="complete">
          {" "}
        </SnackbarContent>
      </>
    ),
    DeveloperName: (
      <>
        <SnackbarContent color="warning" message={d.DeveloperName}>
          {" "}
        </SnackbarContent>
      </>
    ),
    Date: d.CreateDate.split(" ")[0],
    Id: d.id,
    Project: d.Project,
    Subject: d.Subject,
    Description: d.Description.slice(0, 100),
  }));
  const PendingData = Pending.map((d) => ({
    Status: (
      <>
        <SnackbarContent color="danger" message="Pending">
          {" "}
        </SnackbarContent>
      </>
    ),
    DeveloperName: (
      <>
        <SnackbarContent color="warning" message={d.DeveloperName}>
          {" "}
        </SnackbarContent>
      </>
    ),
    Date: d.CreateDate.split(" ")[0],
    Id: d.id,
    Project: d.Project,
    Subject: d.Subject,
    Description: d.Description.slice(0, 100),
  }));
  // ======================================
  function HandleView() {
    if (edit != "") {
      if (viewData) {
        setviewData(false);
        setEdit("");
      } else {
        setviewData(true);
      }
    } else {
      setviewData(false);
    }
  }
  //Edit Button Clicked
  useEffect(() => {
    // console.log("Edited", edit);

    // GetTicketById.php
    let formData = new FormData();

    formData.append("Id", edit);
    axios({
      method: "post",
      url: Domain + "/GetTicketById.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Response for Edit:", response.data);
        if (response.data.success) {
          setTicketDetails(response.data.data);
          HandleView();
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log("Error:", response);
      });
  }, [edit]);

  //To get Developer list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Usertype", "Developer");
    axios({
      method: "post",
      url: Domain + "/getall_users.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle successx
        console.log("Response:", response.data);

        if (response.data.TotalCount != 0 && response.data.success)
          setDevelopers(response.data.data);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  const DevelopersList = Developers.map((d) => ({
    value: d.id,
    label: d.FullName,
  }));
  const [DeveloperValues, setDevelopersValue] = React.useState({
    Id: null,
    label: "",
  });
  console.log("selected ", DeveloperValues);
  const [importantFlag, setImportantFlag] = React.useState("");
  function HandleAssign() {
    if (DeveloperValues.Id != null) {
      setError(false);
      let formData = new FormData();
      formData.append("DeveloperId", DeveloperValues.Id);
      formData.append("DeveloperName", DeveloperValues.Label);
      formData.append("Id", TicketDetials.id);
      formData.append("Status", "Assigned");
      formData.append("Flag", importantFlag);
      axios({
        method: "post",
        url: Domain + "/Assign_toDeveloper.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("Response:", response.data);
          showSavedNotification();
          HandleView();
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          showDeletedNotification;
        });
    } else {
      setError(true);
    }
  }
  // =======================================
  // console.log("checkbox: ", importantFlag);
  function HandleImportant() {
    if (importantFlag != "") {
      setImportantFlag("");
    } else {
      setImportantFlag("Important");
    }
  }
  //Refresh Function
  const HandleRefresh = () => {
    if (!refresh) {
      setRefresh(true);
    } else {
      setRefresh(false);
    }
  };
  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="Ticket Assigned successfully!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Error Please try again!"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />

      {viewData ? (
        <>
          <Card>
            <GridContainer>
              <GridItem md={12} sm={12} lg={4}>
                <img
                  style={{ maxHeight: "200px" }}
                  src={Domain + "/images/" + TicketDetials.Image}
                  alt=".."
                />
              </GridItem>
              <GridItem md={12} sm={12} lg={6}>
                Name : <b>{TicketDetials.FullName}</b>
                <br />
                Subject : <b>{TicketDetials.Subject}</b> <br />
                Ticket Number : <b>{TicketDetials.id}</b> <br />
                Ticket Status : <b>{TicketDetials.Status}</b> <br />
                Project Name : <b>{TicketDetials.Project}</b> <br />
                Issue Description : <b>{TicketDetials.Description}</b> <br />
                Ticket Raised Date : <b>{TicketDetials.CreateDate}</b> <br />
                <GridItem xs={12} sm={12} md={6}>
                  <SingleSelect
                    noOptionsMessage="Create any course first"
                    placeholder="Select Developer"
                    Options={DevelopersList}
                    setValue={setDevelopersValue}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <input
                    type="checkbox"
                    id="impflag"
                    name="impflag"
                    value="important"
                    onChange={HandleImportant}
                  />
                  Important Job
                </GridItem>
                {DevError ? (
                  <>
                    <Danger>Please Select a Developer First</Danger>
                  </>
                ) : (
                  <></>
                )}
                <GridContainer className={classes.pullRight}>
                  <Button round onClick={HandleView}>
                    Go back
                  </Button>
                  <Button color="info" round onClick={HandleAssign}>
                    Assign
                  </Button>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Card>
        </>
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button round onClick={HandleRefresh}>
              Refresh
            </Button>
            <NavPills
              color="info"
              tabs={[
                {
                  tabButton: "Raised Tickets",
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>
                              All tickets Raised
                            </h4>
                            <p className={classes.cardCategoryWhite}>
                              All tickets Raised will be listed below
                            </p>
                          </CardHeader>
                          <CardBody>
                            <LoadingOverlay
                              active={false}
                              spinner
                              text="Please Wait.."
                            >
                              {RaisedEmpty ? (
                                <p>empty</p>
                              ) : (
                                <Table
                                  tableHeaderColor="info"
                                  tableHead={[
                                    "Status",
                                    "Date",
                                    "Ticket Number",
                                    "Project",
                                    "Subject",
                                    "Description",
                                    "Actions",
                                  ]}
                                  tableData={RaisedData}
                                  noAction={false}
                                  setEdit={setEdit}
                                  Images={false}
                                  loading={loading}
                                />
                              )}
                            </LoadingOverlay>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabButton: "Assigned Tickets",
                  tabContent: (
                    <span>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card>
                            <CardHeader color="info">
                              <h4 className={classes.cardTitleWhite}>
                                List of Current Tickets
                              </h4>
                              <p className={classes.cardCategoryWhite}>
                                Currently working Tickets will be listed below
                              </p>
                            </CardHeader>
                            <CardBody>
                              <LoadingOverlay
                                active={false}
                                spinner
                                text="Please Wait.."
                              >
                                {AssignedEmpty ? (
                                  <p>empty</p>
                                ) : (
                                  <Table
                                    tableHeaderColor="info"
                                    tableHead={[
                                      "Status",
                                      "Assigned To",
                                      "Date",
                                      "Ticket Number",
                                      "Project",
                                      "Subject",
                                      "Description",
                                      "Action",
                                    ]}
                                    tableData={AssignedData}
                                    noAction={false}
                                    setEdit={setEdit}
                                    Images={false}
                                    loading={loading}
                                  />
                                )}
                              </LoadingOverlay>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </span>
                  ),
                },
                {
                  tabButton: "Working Tickets",
                  tabContent: (
                    <span>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card>
                            <CardHeader color="info">
                              <h4 className={classes.cardTitleWhite}>
                                List of Completed Tickets
                              </h4>
                              <p className={classes.cardCategoryWhite}>
                                Completed Tickets will be listed below
                              </p>
                            </CardHeader>
                            <CardBody>
                              <LoadingOverlay
                                active={false}
                                spinner
                                text="Please Wait.."
                              >
                                {WorkingEmpty ? (
                                  <p>empty</p>
                                ) : (
                                  <Table
                                    tableHeaderColor="info"
                                    tableHead={[
                                      "Status",
                                      "Assigned To",
                                      "Date",
                                      "Ticket Number",
                                      "Project",
                                      "Subject",
                                      "Description",
                                    ]}
                                    tableData={WorkingData}
                                    noAction={true}
                                    setEdit={setEdit}
                                    Images={false}
                                    loading={loading}
                                  />
                                )}
                              </LoadingOverlay>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </span>
                  ),
                },
                {
                  tabButton: "Completed Tickets",
                  tabContent: (
                    <span>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card>
                            <CardHeader color="info">
                              <h4 className={classes.cardTitleWhite}>
                                List of Completed Tickets
                              </h4>
                              <p className={classes.cardCategoryWhite}>
                                Completed Tickets will be listed below
                              </p>
                            </CardHeader>
                            <CardBody>
                              <LoadingOverlay
                                active={false}
                                spinner
                                text="Please Wait.."
                              >
                                {CompletedEmpty ? (
                                  <p>empty</p>
                                ) : (
                                  <Table
                                    tableHeaderColor="info"
                                    tableHead={[
                                      "Status",
                                      "Assigned To",
                                      "Date",
                                      "Ticket Number",
                                      "Project",
                                      "Subject",
                                      "Description",
                                    ]}
                                    tableData={CompletedData}
                                    noAction={true}
                                    setEdit={setEdit}
                                    Images={false}
                                    loading={loading}
                                  />
                                )}
                              </LoadingOverlay>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </span>
                  ),
                },
                {
                  tabButton: "Pending Tickets",
                  tabContent: (
                    <span>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card>
                            <CardHeader color="info">
                              <h4 className={classes.cardTitleWhite}>
                                List of Pending Tickets
                              </h4>
                              <p className={classes.cardCategoryWhite}>
                                Pending Tickets will be listed below
                              </p>
                            </CardHeader>
                            <CardBody>
                              <LoadingOverlay
                                active={false}
                                spinner
                                text="Please Wait.."
                              >
                                {PendingEmpty ? (
                                  <p>empty</p>
                                ) : (
                                  <Table
                                    tableHeaderColor="info"
                                    tableHead={[
                                      "Status",
                                      "Assigned To",
                                      "Date",
                                      "Ticket Number",
                                      "Project",
                                      "Subject",
                                      "Description",
                                    ]}
                                    tableData={PendingData}
                                    noAction={true}
                                    setEdit={setEdit}
                                    Images={false}
                                    loading={loading}
                                  />
                                )}
                              </LoadingOverlay>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </span>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}
