import React, { useEffect, useState } from "react";
import { Domain } from "Domain";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import axios from "axios";
import Danger from "components/Typography/Danger";
// import ImageUpload from "components/CustomUpload/ImageUpload.js";

import EmptyTable from "components/EmptyTable";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import DataTable from "components/Table/DataTable";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import * as XLSX from "xlsx";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryGrey: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgb(128,128,128)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "500",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardStyle: {
    padding: "10px",
    paddingRight: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function WorkLog() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  // const [edit, setEdit] = useState([]);
  const [Developers, setDevelopers] = useState([]);
  // const [files, setFiles] = useState(null);
  const [validated, setValidated] = useState(true);
  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(false);
  const [AddStaff, setAddStaff] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  console.log(loading);
  // useEffect(() => {
  //   console.log("Date changed", endDate);
  //   console.log(
  //     "Date time:",
  //     new Date(endDate).toISOString().slice(0, 19).replace("T", " ")
  //   );
  //   // console.log("Date and time:", new Date(endDate).getTime() / 1000);
  // }, [endDate]);
  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  async function saveAsExcel() {
    // var data1 = [
    //   { name: "John", city: "Seattle" },
    //   { name: "Mike", city: "Los Angeles" },
    //   { name: "Zach", city: "New York" },
    // ];
    let header = [
      "ID",
      "Developer Name",
      "Project",
      "Assigned",
      "Working",
      "Completed",
      "Status",
    ];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(StaffData, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "18bdd1");
      range.style("border", false);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "Worklog-" + startDate + " to " + endDate + ".xlsx");
      });
    });
  }
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };
  //Form Data
  // const [data, setData] = React.useState({
  //   Id: 0,
  //   FullName: "",
  //   Mobile: "",
  //   Email: "",
  //   Password: "",
  //   Usertype: "Developer",
  //   Image: "",
  // });

  //Function to handle Data input
  // function HandleData(e) {
  //   const newData = { ...data };
  //   newData[e.target.id] = e.target.value;
  //   setData(newData);
  //   console.log(newData);
  // }
  // function HandleClear() {
  //   setData({
  //     Id: 0,
  //     FullName: "",
  //     Mobile: "",
  //     Email: "",
  //     Password: "",
  //     Usertype: "Developer",
  //   });
  // }
  //Function for Validating fields
  function ValidateFields() {
    if (startDate == "") {
      return false;
    } else if (endDate == "") {
      return false;
    } else return true;
  }

  //To get Developer list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Usertype", "Developer");
    axios({
      method: "post",
      url: Domain + "/GetAllWorkLog.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Developers Response:", response.data);
        if (response.data.TotalCount != 0) {
          setDevelopers(response.data.data);
          console.log("setDevelopers ", response.data.data);
          setLoading(false);
        } else {
          setEmpty(true);
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, []);
  const StaffData = Developers.map((d) => ({
    id: d.TicketId,
    DeveloperName: d.DeveloperName,
    ProjectName: d.Project,
    Assigned: d.Assigned,
    Working: d.Working,
    Completed: d.Completed,
    Status: d.Status,
  }));
  // console.log("Developers Data", events);
  //Function to save Data
  function HandleSave() {
    if (ValidateFields()) {
      setValidated(true);
      let formData = new FormData();
      formData.append(
        "StartDate",
        new Date(startDate).toISOString().slice(0, 19).replace("T", " ")
      );
      formData.append(
        "EndDate",
        new Date(endDate).toISOString().slice(0, 19).replace("T", " ")
      );
      axios({
        method: "post",
        url: Domain + "/GetAllWorkLogByDate.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          if (response.data.success) {
            setDevelopers(response.data.data);
            setAddStaff(false);
            setEmpty(false);
            showSavedNotification();
            console.log("saved");
          } else {
            showDeletedNotification;
            console.log("failed");
          }
        })
        .catch(function (response) {
          //handle error
          setDeleted(true);
          console.log(response);
        });
    } else {
      setValidated(false);
    }
  }

  //===================================
  // const [JsonDataToInsert, setJsonDataToInsert] = useState([]);
  // const readUploadFile = (e) => {
  //   e.preventDefault();
  //   if (e.target.files) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const data = e.target.result;
  //       const workbook = XLSX.read(data, { type: "array" });
  //       const sheetName = workbook.SheetNames[0];
  //       const worksheet = workbook.Sheets[sheetName];
  //       const json = XLSX.utils.sheet_to_json(worksheet);
  //       console.log(json);
  //       setJsonDataToInsert(json);
  //     };
  //     reader.readAsArrayBuffer(e.target.files[0]);
  //   }
  // };

  // function HandleBulkInsert() {
  //   let formData = new FormData();
  //   formData.append("JsonData", JsonDataToInsert);
  //   axios({
  //     method: "post",
  //     url: Domain + "/insertbulk_data.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       console.log(response);
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }
  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="WorkLog fetched successfully!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Error! Please try again"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />

      {AddStaff ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <form>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>Filter Work Log</h4>
                  <p className={classes.cardCategoryWhite}>Enter the Details</p>
                </CardHeader>

                <CardBody>
                  <Card className={classes.cardStyle}>
                    <GridContainer>
                      <GridItem xs={6} sm={6} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="From Date"
                            value={startDate}
                            onChange={(newValue) => {
                              setStartDate(
                                newValue.toLocaleDateString("en-CA")
                              );
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="To Date"
                            value={endDate}
                            onChange={(newValue) => {
                              setEndDate(newValue.toLocaleDateString("en-CA"));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </GridItem>
                      {/* <GridItem xs={6} sm={6} md={6}>
                        <form>
                          <label htmlFor="upload">Upload File</label>
                          <input
                            type="file"
                            name="upload"
                            id="upload"
                            onChange={readUploadFile}
                          />
                        </form>
                      </GridItem> */}
                    </GridContainer>
                  </Card>

                  <GridContainer>
                    <GridItem xs={12} sm={5} md={4}>
                      {" "}
                      {validated ? (
                        <></>
                      ) : (
                        <Danger>Select Start and End dates</Danger>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button onClick={() => setAddStaff(false)} color="danger">
                    Cancel
                  </Button>
                  {/* <Button onClick={HandleBulkInsert} color="info">
                    Save Bulk
                  </Button> */}
                  <Button onClick={HandleSave} color="info">
                    Search
                  </Button>
                  <Button onClick={saveAsExcel} color="success">
                    Download Excel
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <>
          <Button onClick={() => setAddStaff(true)} color="info">
            Filter
          </Button>
        </>
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>
                Work Log Report-{" "}
                {startDate != "" ? (
                  <>
                    <b>{startDate}</b> To <b>{endDate}</b>
                  </>
                ) : (
                  <>All</>
                )}
              </h4>
              <p className={classes.cardCategoryWhite}>Showing all work logs</p>
            </CardHeader>
            <CardBody>
              {empty ? (
                <EmptyTable />
              ) : (
                <DataTable
                  columns={[
                    { field: "id", headerName: "Ticket ID", width: 120 },
                    {
                      field: "DeveloperName",
                      headerName: "Developer Name",
                      width: 160,
                    },
                    {
                      field: "ProjectName",
                      headerName: "Project Name",
                      width: 160,
                    },
                    {
                      field: "Assigned",
                      headerName: "Assigned",
                      width: 160,
                    },
                    {
                      field: "Working",
                      headerName: "Working",
                      width: 160,
                    },
                    {
                      field: "Completed",
                      headerName: "Completed",
                      width: 160,
                    },
                    {
                      field: "Status",
                      headerName: "Status",
                      width: 110,
                    },
                  ]}
                  rows={StaffData}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
